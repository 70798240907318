import { FormInstance } from 'antd'
import { FC } from 'react'
import { VerticalSpace } from 'src/sdk/components/layout'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import { BankAccountCreate } from 'src/sdk/datasource/wallet/bankaccount'
import { PaymentMethodAddProps } from '../payment-methods/PaymentMethodAdd'
import './BankAccount.less'
import GoCardlessAddBankAccount from './Forms/Add/GoCardlessAddBankAccount'
import StripeAddBankAccount from './Forms/Add/StripeAddBankAccount'

type BankAccountAddFormProps = {
  form: FormInstance<BankAccountCreate>
} & PaymentMethodAddProps

const BankAccountAddForm: FC<BankAccountAddFormProps> = ({ ...props }) => {
  const { achProcessor } = usePublicConfig()

  const BankAccountForm: FC = () => {
    switch (achProcessor?.name) {
      case 'stripe':
      case 'stripev2':
        return <StripeAddBankAccount {...props} />
      case 'gocardless':
        return <GoCardlessAddBankAccount {...props} processorId={achProcessor.id} />
      default:
        return null
    }
  }
  return (
    <VerticalSpace justify={'center'}>
      {/* <AchConnect block includeOr={'after'} buttonText={'Add and Verify Instantly'} /> */}
      <BankAccountForm />
    </VerticalSpace>
  )
}

export default BankAccountAddForm
