import * as React from 'react'
function SvgNewLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1280 1280' width='1em' height='1em' {...props}>
      <path d='M1088 448v192H448V384L256.5 543.6C151.2 631.4 65 703.5 65 704s86.2 72.6 191.5 160.4L448 1024V768h768V256h-128v192z' />
    </svg>
  )
}
const MemoSvgNewLine = React.memo(SvgNewLine)
export default MemoSvgNewLine
