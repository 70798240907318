import { FC } from 'react'
import { PaymentMethodAddProps } from '../../../payment-methods/PaymentMethodAdd'
import { GoCardlessConnect } from '../../AchConnect'

const GoCardlessAddBankAccount: FC<PaymentMethodAddProps & { processorId: number }> = ({
  processorId,
  onLoading,
  onSuccess,
  onError,
}) => {
  return <GoCardlessConnect onSuccess={onSuccess} onError={onError} onLoading={onLoading} processorId={processorId} />
}
export default GoCardlessAddBankAccount
