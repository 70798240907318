import { useEffect, createContext, PropsWithChildren, ReactElement, ReactNode, useContext, useState } from 'react'
import Cookies from 'js-cookie'
import { AccountEntity, AccountFlag } from '../datasource/account/account'
import { UserCredentials } from '../datasource/token'
import { useController } from 'rest-hooks'
import { useGlobalNetwork } from 'src/components/global-network/GlobalNetwork'
import { FullScreenLoader } from '../components/loader'
import { usePrivateConfig, usePublicConfig } from './Config'
import { useNativeApp } from './NativeApp'

type ContextProps = {
  authenticate: (credentials: UserCredentials) => Promise<AccountEntity>
  authenticated: boolean
  logout: () => Promise<void>
  user?: AccountEntity
  refreshAccount: () => Promise<AccountEntity>
  flag?: AccountFlag
}

const defaultValues = {
  authenticate: (value) => value,
  authenticated: false,
  logout: () => new Promise<void>(() => {}),
  refreshAccount: () => new Promise<AccountEntity>(() => {}),
}

const Auth = createContext<ContextProps>(defaultValues)

const GetAuthCookie = () => Cookies.get('pv.session_id')

function AuthProvider({ children }) {
  const { company } = usePrivateConfig()
  const { setGlobalNetworks, changeNetwork, globalNetworkEnabled } = useGlobalNetwork()
  const { fetch, invalidate } = useController()
  const [loggingOut, setLoggingOut] = useState(false)
  const [authCookie, setAuthCookie] = useState<string | undefined>(GetAuthCookie())
  const [loggedIn, setLoggedIn] = useState<AccountEntity | undefined>()
  const [flag, setFlag] = useState<AccountFlag>()

  const refreshAccount = () => {
    return invalidate(AccountEntity.detail(), { id: loggedIn?.id }).then(() => {
      return fetch(AccountEntity.detail(), {}).then((user) => {
        return user
      })
    })
  }

  // Handle re-routing a customer to the correct portal for global networks
  // Use Case: Customer does not have an account in the Portal Entry point, but has an account in one of the connected global networks
  // Re-route customer to the correct portal
  const handlePortalRouting = (customer: AccountEntity) => {
    return new Promise<AccountEntity>((resolve) => {
      if (!globalNetworkEnabled) return resolve(customer)
      if (company.id !== customer.companyId && customer.globalNetworks?.length > 0) {
        const network = customer.globalNetworks.find((n) => n.id === customer.companyId)
        network && changeNetwork(network)
      } else {
        resolve(customer)
      }
    })
  }

  const logout = () => {
    setLoggingOut(true)
    return AccountEntity.logout()
      .then(() => {
        invalidate(AccountEntity.detail(), {})
        setAuthCookie(undefined)
        setLoggedIn(undefined)
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'LOGOUT' }))
        window.location.href = '/'
      })
      .finally(() => setLoggingOut(false))
  }

  useEffect(() => {
    if (authCookie && !loggedIn) {
      fetch(AccountEntity.detail(), {})
        .then<AccountEntity>()
        .then((response) => {
          window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'customerNumber', data: response.id }))
          setLoggedIn(AccountEntity.fromJS(response))
        })
    }
  }, [])

  const Authenticate = async (credentials: UserCredentials) => {
    return await fetch(AccountEntity.login(), credentials).then((response) => {
      return handlePortalRouting(response).then((customer) => {
        setLoggedIn(response)
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'customerNumber', data: response.id }))
        return customer
      })
    })
  }

  useEffect(() => {
    if (!loggedIn) return
    setAuthCookie(GetAuthCookie())
    setGlobalNetworks(loggedIn.globalNetworks)
    setFlag(loggedIn.flag)
  }, [loggedIn])

  // useEffect(() => {
  //   if (!globalNetworkEnabled || !loggedIn) return
  //   if (currentNetwork?.id !== loggedIn?.companyId) {
  //     changeNetwork(loggedIn.companyId)
  //   }
  // }, [currentNetwork])

  return (
    <Auth.Provider
      value={{
        authenticate: Authenticate,
        authenticated: !!authCookie,
        refreshAccount,
        user: loggedIn,
        logout,
        flag,
      }}
    >
      {children}
      <FullScreenLoader loading={loggedIn ? loggingOut : false} />
    </Auth.Provider>
  )
}

function useAuth(): ContextProps {
  return useContext(Auth)
}

export { AuthProvider, Auth, useAuth }
