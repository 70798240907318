import { default as AntIcon } from '@ant-design/icons'
import { FC, useMemo } from 'react'
import { ReactSVG } from 'react-svg'
import { withPrefix } from 'src/sdk/contexts/Config'
import { IconProps } from './Icon'
import './Icon.less'

export type IvyIconProps = {
  type: Design.IvyIcon
  theme?: Design.IconTheme
  height?: number
  style?: React.CSSProperties
  color?: 'success' | 'primary' | 'warning' | 'danger' | 'error' | 'info' | 'white' | 'secondary' | 'default'
  background?: 'primary' | 'warning' | 'danger' | 'error' | 'info' | 'light' | 'dark' | 'none'
}

const SvgIcon: FC<IvyIconProps> = ({ type, theme, color }) => (
  <ReactSVG style={{ stroke: color }} src={`/assets/${theme}/${type}.svg`} />
)

const IvyIcon: FC<IvyIconProps & Partial<IconProps>> = ({
  type,
  theme = 'outlined',
  size,
  height,
  wrapperSize,
  className = '',
  color,
  background,
  style,
  ...iconProps
}) => {
  const icon = useMemo(() => () => <SvgIcon type={type} theme={theme} color={color} />, [type, theme, color])
  return (
    <AntIcon
      style={{ ...style, fontSize: size, width: size, height: height }}
      component={icon}
      className={withPrefix(
        className,
        color ? `ivy-icon-${color}` : '',
        background ? `ivy-icon-background-${background}` : '',
      )}
      {...iconProps}
    />
  )
}

export default IvyIcon
